/**制剂查看页面 */
import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'
// 根据ID查询制剂（别名+属性+规格）
export function getBasicInfoApi(id, searchValue) {
  return request({
    url: '/dkm/preparation/' + praseStrEmpty(id),
    method: 'get',
    params: { preparationId: searchValue }
  })
}
//制剂:根据制剂id查询所属药物信息 (药物成分+药物组+中西草标志)
export function getDrugInfoApi(data) {
  return request({
    url: '/dkm/preparation/selectDrugByPreparationId',
    method: 'post',
    params: data
  })
}
// 制剂分类
export function getClassifyApi(data) {
  return request({
    url: '/dkm/category/dir/getDrugCategoryTree',
    method: 'post',
    params: data
  })
}
// 药品:根据制剂id查询属于该制剂的药品列表
export function getDrugDataApi(data) {
  return request({
    url: '/dkm/drugProd/selectByPreparationId',
    method: 'post',
    params: data
  })
}
//制剂:根据制剂id查询同类药物制剂（排除了自己）同类药物制剂
export function getSimilarDataApi(data) {
  return request({
    url: '/dkm/preparation/spec/selectByPreparationId',
    method: 'post',
    params: data
  })
}
