var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "preparation-wrap", staticStyle: { padding: "1rem" } },
    [
      _c(
        "div",
        {
          staticStyle: {
            background: "#FFFFFF",
            "padding-left": "20px",
            "margin-bottom": "10px",
          },
        },
        [
          _vm.reload
            ? _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.jump },
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                [
                  _vm.tabs[0].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[0].title, name: "0" },
                      })
                    : _vm._e(),
                  _vm.tabs[1].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[1].title, name: "1" },
                      })
                    : _vm._e(),
                  _vm.tabs[2].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[2].title, name: "2" },
                      })
                    : _vm._e(),
                  _vm.tabs[3].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[3].title, name: "3" },
                      })
                    : _vm._e(),
                  _vm.tabs[4].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[4].title, name: "4" },
                      })
                    : _vm._e(),
                  _vm.tabs[5].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[5].title, name: "5" },
                      })
                    : _vm._e(),
                  _vm.tabs[6].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[6].title, name: "6" },
                      })
                    : _vm._e(),
                  _vm.tabs[7].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[7].title, name: "7" },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          ref: "scrollContent",
          staticClass: "scroll-content recordContent",
          style:
            "overflow-x: hidden; overflow-y: auto;height:" +
            _vm.contentStyleObj.height,
          on: { scroll: _vm.onScroll },
        },
        [
          _c(
            "el-row",
            { staticClass: "substanceName" },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "padding-left": "15px" },
                  attrs: { span: 24 },
                },
                [
                  _c("span", { staticStyle: { "margin-right": "5px" } }, [
                    _vm._v(_vm._s(_vm.aliasObj.defaultName)),
                  ]),
                  _c("span", { staticStyle: { "margin-right": "5px" } }, [
                    _vm._v("(id: " + _vm._s(_vm.preparationId) + ")"),
                  ]),
                  _c("code-icon", {
                    attrs: { iconCode: _vm.classifyObj.classifyIcon, size: 26 },
                  }),
                  _vm._l(_vm.classifyIcons, function (item, index) {
                    return _c("code-icon", {
                      key: index,
                      staticStyle: { "margin-left": "5px" },
                      attrs: { iconCode: item, size: 26 },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[0].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure bacg-el-row" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("制剂别名")])]
                  ),
                  _c("el-col", { staticClass: "content bacg" }, [
                    _c(
                      "ul",
                      _vm._l(_vm.preparationAliasList, function (item) {
                        return _c(
                          "li",
                          {
                            key: item.id,
                            staticClass: "substanceLi background-li",
                          },
                          [
                            _c("span", [_vm._v(_vm._s(item.preparationAlias))]),
                            _c("code-icon", {
                              staticStyle: {
                                width: "24px",
                                height: "16px",
                                "margin-left": "5px",
                              },
                              attrs: { iconCode: item.languageIcon, size: 24 },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[1].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [
                      _c("span", [
                        _vm._v("药物"),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("成分"),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "substanceTable content",
                      staticStyle: { width: "60%", "min-width": "600px" },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.medicalCompoundsTableData,
                            border: "",
                            stripes: "",
                            "header-cell-style": { "text-align": "center" },
                            "cell-style": { "text-align": "center" },
                            "span-method": _vm.objectSpanMethod,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "drugName", label: "药物名" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "link",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goToLink(
                                              scope.row,
                                              "drug"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.drugName))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "substanceName", label: "成分物质" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "link",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goToLink(
                                              scope.row,
                                              "substance"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.substanceName))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "substanceTypeDesc",
                              label: "成分类型",
                              width: "140",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[2].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure bacg-el-row" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("药物组")])]
                  ),
                  _vm.drugGroupData.length > 0
                    ? _c(
                        "el-col",
                        { staticClass: "content clearfix bacg" },
                        _vm._l(_vm.drugGroupData, function (item) {
                          return _c(
                            "li",
                            {
                              key: item.drugGroupId,
                              staticClass: "substanceLi background-li link",
                              on: {
                                click: function ($event) {
                                  return _vm.goToLink(item, "drugGroup")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.drugGroupName) + " ")]
                          )
                        }),
                        0
                      )
                    : _c("el-col", { staticClass: "content bacg clearfix" }, [
                        _c(
                          "li",
                          {
                            staticClass: "substanceLi background-li no-data-li",
                          },
                          [_vm._v("暂无数据")]
                        ),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[3].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure bacg-el-row" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("制剂属性")])]
                  ),
                  _c("el-col", { staticClass: "content clearfix bacg" }, [
                    _vm.isShowPropertyCommon(_vm.propertyObj.typeDesc)
                      ? _c("li", { staticClass: "substanceLi background-li" }, [
                          _c("span", [_vm._v("剂型：")]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.propertyObj.typeDesc)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.isShowPropertyCommon(_vm.propertyObj.routeStr)
                      ? _c("li", { staticClass: "substanceLi background-li" }, [
                          _c("span", [_vm._v("给药途径：")]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.propertyObj.routeStr)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.isShowPropertyCommon(_vm.propertyObj.siteStr)
                      ? _c("li", { staticClass: "substanceLi background-li" }, [
                          _c("span", [_vm._v("给药部位：")]),
                          _c("span", [_vm._v(_vm._s(_vm.propertyObj.siteStr))]),
                        ])
                      : _vm._e(),
                    _vm.isShowPropertyCommon(_vm.propertyObj.drugTypeSpecsBean)
                      ? _c("li", { staticClass: "substanceLi background-li" }, [
                          _c("span", [_vm._v("中药类别与规格：")]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.propertyObj.drugTypeSpecsBean)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.isShowPropertyCommon(_vm.propertyObj.drugProcMethodBean)
                      ? _c("li", { staticClass: "substanceLi background-li" }, [
                          _c("span", [_vm._v("中药炮制方法：")]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.propertyObj.drugProcMethodBean)),
                          ]),
                        ])
                      : _vm._e(),
                    !_vm.isShowProperty
                      ? _c(
                          "li",
                          {
                            staticClass: "substanceLi background-li no-data-li",
                          },
                          [_vm._v("暂无数据")]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[4].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure bacg-el-row" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("制剂规格")])]
                  ),
                  _vm.ppSpecBeanList.length > 0
                    ? _c(
                        "el-col",
                        { staticClass: "content bacg clearfix" },
                        _vm._l(_vm.ppSpecBeanList, function (item) {
                          return _c(
                            "li",
                            {
                              key: item.id,
                              staticClass: "substanceLi background-li",
                            },
                            [_vm._v(" " + _vm._s(item.showSpec) + " ")]
                          )
                        }),
                        0
                      )
                    : _c("el-col", { staticClass: "content bacg clearfix" }, [
                        _c(
                          "li",
                          {
                            staticClass: "substanceLi background-li no-data-li",
                          },
                          [_vm._v("暂无数据")]
                        ),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[5].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure bacg-el-row" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("制剂分类")])]
                  ),
                  _vm.classifyList.length > 0
                    ? _c(
                        "el-col",
                        {
                          staticClass: "clearfix",
                          staticStyle: { "margin-left": "20px" },
                        },
                        _vm._l(_vm.classifyList, function (item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              ref: "classify-li" + index,
                              refInFor: true,
                              staticClass: "fl classify-li",
                            },
                            [
                              _c("el-tree", {
                                staticClass: "classify-tree",
                                attrs: {
                                  data: item,
                                  props: _vm.defaultProps,
                                  "empty-text": "暂无数据",
                                  "node-key": "id",
                                  "expand-on-click-node": false,
                                  "default-expand-all": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var node = ref.node
                                        var data = ref.data
                                        return _c(
                                          "span",
                                          { staticClass: "custom-tree-node" },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(node.label)),
                                            ]),
                                          ]
                                        )
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _c("el-col", { staticClass: "content clearfix bacg" }, [
                        _c(
                          "li",
                          {
                            staticClass: "substanceLi background-li no-data-li",
                          },
                          [_vm._v("暂无数据")]
                        ),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[6].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [
                      _c("span", [
                        _vm._v("相关"),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("药品"),
                        ]),
                        _vm._v("（属于该制剂的药品）"),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "substanceTable content",
                      staticStyle: { width: "80%", "min-width": "800px" },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "drugTable",
                          attrs: {
                            data: _vm.drugTableData,
                            border: "",
                            stripes: "",
                            "header-cell-style": { "text-align": "center" },
                            "cell-style": _vm.cellStyle,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "名称" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "link",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goToLink(
                                              scope.row,
                                              "drugProd"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.aliasName))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "ingredientStr",
                              label: "成分",
                              width: "180",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.dkbDpSpecIngredientList,
                                    function (ingredient, index) {
                                      return _c(
                                        "span",
                                        { key: ingredient.id },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "link",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goToLink(
                                                    ingredient,
                                                    "substance"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    ingredient.substanceName
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "unit-link" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f(
                                                        "relatedDrugsIngredient"
                                                      )(ingredient)
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          index <
                                          scope.row.dkbDpSpecIngredientList
                                            .length -
                                            1
                                            ? _c("span", [_vm._v("+")])
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "address", label: "剂型" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.dkbPpInfo.preparationForm
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "规格包装" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", {
                                      staticStyle: {
                                        "white-space": "pre-line",
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(scope.row.prodStr),
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "生产厂商" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", {
                                      staticStyle: {
                                        "white-space": "pre-line",
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          scope.row.manufacturerStr
                                        ),
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[7].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [
                      _c("span", [
                        _vm._v("同类药物"),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("制剂"),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "substanceTable content",
                      staticStyle: { width: "80%", "min-width": "800px" },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "similarTable",
                          attrs: {
                            data: _vm.similarTableData,
                            border: "",
                            stripes: "",
                            "header-cell-style": { "text-align": "center" },
                            "cell-style": { "text-align": "center" },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "drugName", label: "名称" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "link",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goToLink(
                                              scope.row,
                                              "preparation"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.preparationName) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "preparationForm",
                              label: "剂型",
                              width: "120",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "preparationSpecDesc",
                              label: "规格",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm._f("specStr")(scope.row))
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "routeName", label: "用药途径" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }