<template>
  <div class="preparation-wrap" style="padding: 1rem;">
    <div style="background: #FFFFFF;padding-left: 20px;margin-bottom: 10px;">
      <el-tabs v-if="reload" v-model="tabIndex" @tab-click="jump">
        <el-tab-pane :label="tabs[0].title" v-if="tabs[0].isShow" name="0"></el-tab-pane>
        <el-tab-pane :label="tabs[1].title" v-if="tabs[1].isShow" name="1"></el-tab-pane>
        <el-tab-pane :label="tabs[2].title" v-if="tabs[2].isShow" name="2"></el-tab-pane>
        <el-tab-pane :label="tabs[3].title" v-if="tabs[3].isShow" name="3"></el-tab-pane>
        <el-tab-pane :label="tabs[4].title" v-if="tabs[4].isShow" name="4"></el-tab-pane>
        <el-tab-pane :label="tabs[5].title" v-if="tabs[5].isShow" name="5"></el-tab-pane>
        <el-tab-pane :label="tabs[6].title" v-if="tabs[6].isShow" name="6"></el-tab-pane>
        <el-tab-pane :label="tabs[7].title" v-if="tabs[7].isShow" name="7"></el-tab-pane>
      </el-tabs>
    </div>
    <div ref="scrollContent" class="scroll-content recordContent" @scroll="onScroll" :style="'overflow-x: hidden; overflow-y: auto;height:' + contentStyleObj.height">
      <el-row class="substanceName">
        <el-col :span="24" style="padding-left:15px;">
          <!-- <i class="el-icon-info" title="点击查看来源引用"></i> -->
          <span style="margin-right: 5px;">{{ aliasObj.defaultName }}</span>
          <span style="margin-right: 5px;">(id: {{preparationId}})</span>
          <!-- <i class="el-icon-edit"></i> -->
          <code-icon :iconCode="classifyObj.classifyIcon" :size="26"/>
          <code-icon
            v-for="(item, index) in classifyIcons"
            :key="index"
            :iconCode="item"
            :size="26"
            style="margin-left: 5px;" />
          <!-- <span v-show="classifyObj.classifyDesc" style="font-size: 12px;color: #2D5AFA;border-radius: 50%;border: 1px solid #2D5AFA;padding: 2px 4px;">{{ classifyObj.classifyDesc | classifyDesc }}</span> -->
        </el-col>
      </el-row>
      <!-- 制剂别名 -->
      <div :ref="tabs[0].refName" class="scroll-item">
        <el-row class="substanceStructure bacg-el-row">
          <el-col :span="24" class="substanceTitle">
            <span>制剂别名</span>
          </el-col>
          <el-col class="content bacg">
            <ul>
              <li
                v-for="item in preparationAliasList"
                :key="item.id"
                class="substanceLi background-li">
                <!-- <i class="el-icon-info" title="点击查看来源引用"></i> -->
                <span>{{ item.preparationAlias }}</span>
                <!-- <svg-icon v-if="item.languageId === 100001" icon-class="china" style="width: 24px;height: 16px;margin-left: 5px;"/>
                <svg-icon v-else icon-class="yingguoguoqi" style="width: 24px;height: 16px;margin-left: 5px;"/> -->
                <code-icon :iconCode="item.languageIcon" :size="24" style="width: 24px;height: 16px;margin-left: 5px;"/>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
      <!-- 药物成分 -->
      <div :ref="tabs[1].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>药物<span style="color: red;">成分</span></span>
          </el-col>
          <el-col class="substanceTable content" style="width: 60%; min-width: 600px;">
            <el-table
              :data="medicalCompoundsTableData"
              border
              stripes
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              :span-method="objectSpanMethod">
              <el-table-column
                prop="drugName"
                label="药物名">
                <template slot-scope="scope">
                  <span class="link" @click="goToLink(scope.row, 'drug')">{{ scope.row.drugName }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="substanceName"
                label="成分物质">
                <template slot-scope="scope">
                  <span class="link" @click="goToLink(scope.row, 'substance')">{{ scope.row.substanceName }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="substanceTypeDesc"
                label="成分类型"
                width="140">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <!-- 药物组 -->
      <div :ref="tabs[2].refName" class="scroll-item">
        <el-row class="substanceStructure bacg-el-row">
          <el-col :span="24" class="substanceTitle">
            <span>药物组</span>
          </el-col>
          <el-col v-if="drugGroupData.length > 0" class="content clearfix bacg">
            <li
              v-for="item in drugGroupData"
              :key="item.drugGroupId"
              class="substanceLi background-li link"
              @click="goToLink(item, 'drugGroup')"> 
              {{ item.drugGroupName }}
            </li>
          </el-col>
          <el-col v-else class="content bacg clearfix">
            <li class="substanceLi background-li no-data-li">暂无数据</li>
          </el-col>
        </el-row>
      </div>
      <!-- 制剂属性 -->
      <div :ref="tabs[3].refName" class="scroll-item">
        <el-row class="substanceStructure bacg-el-row">
          <el-col :span="24" class="substanceTitle">
            <span>制剂属性</span>
          </el-col>
          <el-col class="content clearfix bacg">
            <li v-if="isShowPropertyCommon(propertyObj.typeDesc)" class="substanceLi background-li">
              <span>剂型：</span>
              <span>{{ propertyObj.typeDesc }}</span>
            </li>
            <li v-if="isShowPropertyCommon(propertyObj.routeStr)" class="substanceLi background-li">
              <span>给药途径：</span>
              <span>{{ propertyObj.routeStr }}</span>
            </li>
            <li v-if="isShowPropertyCommon(propertyObj.siteStr)" class="substanceLi background-li">
              <span>给药部位：</span>
              <span>{{ propertyObj.siteStr }}</span>
            </li>
            <li v-if="isShowPropertyCommon(propertyObj.drugTypeSpecsBean)" class="substanceLi background-li">
              <span>中药类别与规格：</span>
              <span>{{ propertyObj.drugTypeSpecsBean }}</span>
            </li>
            <li v-if="isShowPropertyCommon(propertyObj.drugProcMethodBean)" class="substanceLi background-li">
              <span>中药炮制方法：</span>
              <span>{{ propertyObj.drugProcMethodBean }}</span>
            </li>
            <li v-if="!isShowProperty" class="substanceLi background-li no-data-li">暂无数据</li>
          </el-col>
        </el-row>
      </div>
      <!-- 制剂规格 -->
      <div :ref="tabs[4].refName" class="scroll-item">
        <el-row class="substanceStructure bacg-el-row">
          <el-col :span="24" class="substanceTitle">
            <span>制剂规格</span>
          </el-col>
          <el-col v-if="ppSpecBeanList.length > 0" class="content bacg clearfix">
            <li
              v-for="item in ppSpecBeanList"
              :key="item.id"
              class="substanceLi background-li">
              {{ item.showSpec }}
            </li>
          </el-col>
          <el-col v-else class="content bacg clearfix">
            <li class="substanceLi background-li no-data-li">暂无数据</li>
          </el-col>
        </el-row>
      </div>
      <!-- 制剂分类 -->
      <div :ref="tabs[5].refName" class="scroll-item">
        <el-row class="substanceStructure bacg-el-row">
          <el-col :span="24" class="substanceTitle">
            <span>制剂分类</span>
          </el-col>
          <el-col v-if="classifyList.length > 0" class="clearfix" style="margin-left: 20px;">
            <li class="fl classify-li"
              v-for="(item, index) in classifyList"
              :key="index"
              :ref="'classify-li' + index">
            <el-tree
              :data="item"
              :props="defaultProps"
              empty-text="暂无数据"
              node-key="id"
              class="classify-tree"
              :expand-on-click-node="false"
              default-expand-all>
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <!-- <code-icon v-if="data.icon" :iconCode="data.icon" :size="16" style="width: 16px;height: 16px;margin-left: 5px;"/> -->
              </span>
              </el-tree>
            </li>
          </el-col>
          <el-col v-else class="content clearfix bacg">
            <li class="substanceLi background-li no-data-li">暂无数据</li>
          </el-col>
          <!-- <el-col :span="24">
            <div
              v-for="(item, index) in classifyList"
              :key="index"
              style="display:inline-block;margin: 0 0px 20px 20px;">
              <treeselect style="width: 200px;"
                :value="item[0].valueId"
                :options="item"
                :normalizer="normalizerTreeNode"
                :disable-branch-nodes="true"
                :show-count="true"
                @select="changeType" />
            </div>
          </el-col> -->
          <!-- <el-select class="form-input" v-model="node.name" filterable style="width: 200px;padding-left: 20px;">
            <el-option style="height: auto;" :value="node">
              <el-tree
                :data="classifyList"
                default-expand-all
                show-checkbox
                check-strictly
                ref="treeForm"
                node-key="id"
                :expand-on-click-node="false"
                :props="props"
                @check="handleNodeCheck"
                @check-change="handleClick"
                @node-click="handleNodeClick">
              </el-tree>
            </el-option>
          </el-select> -->
        </el-row>
      </div>
      <!-- 相关药品（属于该制剂的药品） -->
      <div :ref="tabs[6].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>相关<span style="color: red;">药品</span>（属于该制剂的药品）</span>
          </el-col>
          <el-col class="substanceTable content" style="width: 80%;min-width: 800px;">
            <el-table
              :data="drugTableData"
              border
              stripes
              ref="drugTable"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="cellStyle">
              <el-table-column
                label="名称">
                <template slot-scope="scope">
                  <span class="link" @click="goToLink(scope.row, 'drugProd')">{{ scope.row.aliasName }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="ingredientStr"
                label="成分"
                width="180">
                <template slot-scope="scope">
                  <!-- <span style="margin-left: 10px">{{ scope.row.ingredientStr }}</span> -->
                  <span
                    v-for="(ingredient, index) in scope.row.dkbDpSpecIngredientList"
                    :key="ingredient.id">
                    <span class="link" @click="goToLink(ingredient, 'substance')">
                      {{ ingredient.substanceName }}
                      <span class="unit-link">{{ ingredient | relatedDrugsIngredient }}</span>
                      <!-- <span class="unit-link">（{{ ingredient.substanceQtyMax }}</span>
                      <span class="unit-link">{{ ingredient.substanceQtyUnitDesc }}）</span> -->
                    </span>
                    <span v-if="index < scope.row.dkbDpSpecIngredientList.length - 1">+</span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="address"
                label="剂型">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.dkbPpInfo.preparationForm }}</span>
                </template>
              </el-table-column>
              <!-- <el-table-column
                prop="name"
                label="用药途径"
                width="120">
              </el-table-column> -->
              <el-table-column
                label="规格包装">
                <template slot-scope="scope">
                  <div v-html="scope.row.prodStr" style="white-space: pre-line;"></div>
                </template>
              </el-table-column>
              <!-- <el-table-column
                prop="manufacturerName"
                label="生产厂商">
              </el-table-column> -->
              <el-table-column
                label="生产厂商">
                <template slot-scope="scope">
                  <div v-html="scope.row.manufacturerStr" style="white-space: pre-line;"></div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <!-- 同类药物制剂 -->
      <div :ref="tabs[7].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>同类药物<span style="color: red;">制剂</span></span>
          </el-col>
          <el-col class="substanceTable content" style="width: 80%;min-width: 800px;">
            <el-table
              :data="similarTableData"
              border
              stripes
              ref="similarTable"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}">
              <el-table-column
                prop="drugName"
                label="名称">
                <template slot-scope="scope">
                  <span class="link" @click="goToLink(scope.row, 'preparation')">
                    {{ scope.row.preparationName }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="preparationForm"
                label="剂型"
                width="120">
              </el-table-column>
              <el-table-column
                prop="preparationSpecDesc"
                label="规格">
                <template slot-scope="scope">
                  <span>{{ scope.row | specStr }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="routeName"
                label="用药途径">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import $request from '@/utils/request'
import {
  getBasicInfoApi,
  getDrugInfoApi,
  getClassifyApi,
  getDrugDataApi,
  getSimilarDataApi
} from '@/api/dkm/preparation/view.js'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: 'PreparationSpecificationView',
  components: { Treeselect },
  filters: {
    classifyDesc(str) {
      return str.substring(0, 1)
    },
    relatedDrugsIngredient(ingredient) {
      if (ingredient.substanceQtyMax || ingredient.substanceQtyUnitDesc) {
        return '(' + ingredient.substanceQtyMax + ingredient.substanceQtyUnitDesc + ')'
      }
    },
    // 规格字段处理
    specStr(row) {
      let spec = ''
      if (row.maxConcentration) {
        return row.maxConcentration + '*' + row.preparationSpecDesc
      } else {
        return row.preparationSpecDesc
      }
    }
  },
  data() {
    return {
      preparationId: null, // 制剂ID
      classifyObj: {
        classifyDesc: '',
        classifyIcon: '',
        classify: '' // 中西草标识描述
      },
      aliasObj: {
        defaultName: '',
        classifyIcon: ''
      }, // 别名对象
      preparationAliasList: [], // 别名数组
      // 制剂属性
      propertyObj: {
        ppRouteBeanList: [], // 给药途径 routeName
        ppSiteBeanList: [], // 给药部位
        typeDesc: '', // 剂型名称
        routeStr: '',
        siteStr: '',
        drugProcMethodBean: '', // 中药炮制方法
        drugTypeSpecsBean: '' // 中药类别与规格
      },
      // 制剂规格
      ppSpecBeanList: [], // 制剂规格列表 showSpec
      value: '',
      reload: true,
      tabIndex: '0',
      contentStyleObj: {
        height: '100px'
      },
      tabs: [
        {
          isShow: true,
          title: '制剂别名',
          refName: 'setOneRef'
        },
        {
          isShow: true,
          title: '药物成分',
          refName: 'setTwoRef'
        },
        {
          isShow: true,
          title: '药物组',
          refName: 'setThreeRef'
        },
        {
          isShow: true,
          title: '制剂属性',
          refName: 'setFourRef'
        },
        {
          isShow: true,
          title: '制剂规格',
          refName: 'setFiveRef'
        },
        {
          isShow: true,
          title: '制剂分类',
          refName: 'setSixRef'
        },
        {
          isShow: true,
          title: '相关药品',
          refName: 'setSevenRef'
        },
        {
          isShow: true,
          title: '同类药物制剂',
          refName: 'setEightRef'
        }
      ],
      // activeName: '1',
      medicalCompoundsTableData: [], // 药物成分
      drugGroupData: [], // 药物组
      props: {
        children: 'children',
        label: 'name',
        value: 'id'
      },
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      classifyList: [],
      classifyIcons: [], // 分类图标
      node: {}, //选中的记录
      drugTableData: [], // 相关药品（属于该制剂的药品）
      drugObj: {
        total: 0,
        pageNum: 1,
        pageSize: 9999,
        preparationId: ''
      },
      // 同类药物制剂
      similarTableData: [],
      similarObj: {
        total: 0,
        pageNum: 1,
        pageSize: 9999,
        preparationId: ''
      },
      scrollY: 0
    }
  },
  computed: {
    treeSelectValue() {
      return function (item) {

      }
    },
    isShowProperty() {
      // if (this.propertyObj.typeDesc === '' && this.propertyObj.routeStr === '' && this.propertyObj.siteStr === '') {
      //   return false
      // } else {
      //   return true
      // }
      let typeDescFlag = false
      let routeStrFlag = false
      let siteStrFlag = false
      let drugTypeSpecsBeanFlag = false
      let drugProcMethodBeanFlag = false
      if (this.propertyObj.typeDesc !== '' && this.propertyObj.typeDesc) {
        typeDescFlag = true
      } else {
        typeDescFlag = false
      }
      if (this.propertyObj.routeStr !== '' && this.propertyObj.routeStr) {
        routeStrFlag = true
      } else {
        routeStrFlag = false
      }
      if (this.propertyObj.siteStr !== '' && this.propertyObj.siteStr) {
        siteStrFlag = true
      } else {
        siteStrFlag = false
      }
      if (this.propertyObj.drugTypeSpecsBean !== '' && this.propertyObj.drugTypeSpecsBean) {
        drugTypeSpecsBeanFlag = true
      } else {
        drugTypeSpecsBeanFlag = false
      }
      if (this.propertyObj.drugProcMethodBean !== '' && this.propertyObj.drugProcMethodBean) {
        drugProcMethodBeanFlag = true
      } else {
        drugProcMethodBeanFlag = false
      }
      // 三个都为false，则return false
      if (typeDescFlag || routeStrFlag || siteStrFlag || drugTypeSpecsBeanFlag || drugProcMethodBeanFlag) {
        return true
      } else {
        return false
      }
    },
    isShowPropertyCommon() {
      return function (property) {
        if (property !== '' && property) {
          return true
        } else {
          return false
        }
      }
    }
  },
  async activated() {
    this.classifyIcons = []
    this.preparationId = this.$route.params.id
    this.drugObj.preparationId = this.preparationId
    this.similarObj.preparationId = this.preparationId
    this.getHight()
    window.addEventListener('resize', this.getHight)
    await this.fetchData()
    this.$nextTick(() => {
      document.querySelector('.recordContent').scrollTop = this.scrollY
      for (let i = 0; i < this.classifyList.length; i++) {
        let width = this.$refs['classify-li' + i][0].offsetWidth
        this.$refs['classify-li' + i][0].style.width = width + 'px'
      }
    })
  },
  async mounted() {
    let $this = this
    this.$nextTick(() => {
      let dom = this.$refs.similarTable.bodyWrapper
      /* dom.addEventListener("scroll", async function() {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight
        // console.log(scrollDistance)
        if(scrollDistance <= 10) { //等于0证明已经到底，可以请求接口
          if($this.similarObj.pageNum < $this.similarObj.total){//当前页数小于总页数就请求
            $this.similarObj.pageNum++ //当前页数自增
            //请求接口的代码
            await $this.fetchSimilarData(true)
          }
        }
      }) */
    })
  },
  beforeRouteEnter (to, from, next) {
    if (to.name === 'PreparationSpecificationView') {
      next(vm => {
        const scrollContent = vm.$refs.scrollContent
        // 记录滚动高度
        scrollContent.scrollTop = vm.$route.meta.scroll || 0
        vm.scrollY = vm.$route.meta.scroll
      })
    } else {
      next(vm => {
        const scrollContent = vm.$refs.scrollContent
        // 不记录滚动高度
        scrollContent.scrollTop = 0
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    if (from.name === 'PreparationSpecificationView') {
      const scrollContent = this.$refs.scrollContent
      this.$route.meta.scroll = scrollContent.scrollTop
    }
    next()
  },
  methods: {
    treeSelectValueId(parent, kids) {
      // if (Array.isArray(item)) {
      //   for (let i = 0; i < item.length; i++) {
      //     if (item[i].children && item[i].children.length > 0) {
      //       for (let j = 0; j < item[i].children.length; j++) {
      //         if (!item[i].children[j].children || (item[i].children[j].children.length === 0)) {
      //           console.log(item[i])
      //           return item[i].valueId
      //         } else {
      //           this.treeSelectValueId(item[i].children)
      //         }
      //       }
      //     } else {
      //       return item[i].valueId
      //     }
      //   }
      // } else {
      //   return item.valueId
      // }
      if (parent[0].children && parent[0].children.length > 0) {
        for (let i = 0; i < parent[0].children.length; i++) {
          if (parent[0].children[0].children && parent[0].children[0].children > 0) {
            this.treeSelectValueId(parent[0].children, parent[0].children[0].children)
          } else {
            // console.log(item[0])
            return parent[0].valueId
          }
        }
        returnparent[0].valueId
      } else {
        return parent[0].valueId
        // console.log(item[0].valueId)
      }
    },
    async fetchData() {
      this.propertyObj.siteStr = ''
      this.propertyObj.routeStr = ''
      let siteArr = []
      let routeArr = []
      let res = await getBasicInfoApi(this.preparationId)
      if (res.code === 200) {
        this.preparationAliasList = (res.data && res.data.preparationAliasList) || []
        this.propertyObj.typeDesc = res.data && res.data.typeDesc
        this.propertyObj.ppRouteBeanList = (res.data && res.data.ppRouteBeanList) || []
        this.propertyObj.ppSiteBeanList = (res.data && res.data.ppSiteBeanList) || []
        this.ppSpecBeanList = (res.data && res.data.ppSpecBeanList) || []
        this.propertyObj.ppRouteBeanList.forEach(item => {
          routeArr.push(item.routeName)
        })
        this.propertyObj.ppSiteBeanList.forEach(item => {
          siteArr.push(item.siteName)
        })
        this.propertyObj.routeStr = routeArr.join(',')
        this.propertyObj.siteStr = siteArr.join(',')
        this.propertyObj.drugProcMethodBean = res.data.drugProcMethodBean && res.data.drugProcMethodBean.conceptVal
        this.propertyObj.drugTypeSpecsBean = res.data.drugTypeSpecsBean && res.data.drugTypeSpecsBean.conceptVal
        this.aliasObj.defaultName = this.preparationAliasList.find(item => item.isDefault).preparationAlias
      }
      let drugRes = await getDrugInfoApi({ preparationId: this.preparationId })
      if (drugRes.code === 200) {
        this.classifyObj.classifyDesc = drugRes.data && drugRes.data.classifyDesc
        this.classifyObj.classifyIcon = drugRes.data && drugRes.data.classifyIcon
        let drugName = ''
        if (drugRes.data && drugRes.data.dkbDrugAliasList) {
          drugName = drugRes.data.dkbDrugAliasList.find(item => item.isPrimary === '1').alias
        }
        this.medicalCompoundsTableData = drugRes.data && drugRes.data.dkbDrugIngredientList
        this.medicalCompoundsTableData.forEach(item => {
          item.drugName = drugName
        })
        /* this.medicalCompoundsTableData = [
          {
            drugName: '阿莫西林',
            substanceName: 'chen1',
            substanceTypeDesc: '1'
          },
          {
            drugName: '阿莫西林',
            substanceName: 'chen2',
            substanceTypeDesc: '2'
          },
          {
            drugName: '阿莫西林',
            substanceName: 'chen3',
            substanceTypeDesc: '3'
          }
        ] */
        this.drugGroupData = drugRes.data && drugRes.data.dkbDrugGroupMappingList
      }
      const obj = {
        dataId: this.preparationId,
        dataType: 4
      }
      let classifyRes = await getClassifyApi(obj)
      if (classifyRes.code === 200) {
        let classifyList = classifyRes.data || []
        let arr = this.classifyDataHandle(classifyList)
        this.classifyList = arr
        this.classifyIcons = [...new Set(this.classifyIcons)] 
      }
      await this.fetchDrugData()
      await this.fetchSimilarData()
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: this.medicalCompoundsTableData.length,
            colspan: 1
          }
        } else {
          // 一定要将除了第一行第一列之外的 第一列其他行删除
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      } else {
        return {
          rowspan: 1,
          colspan: 1
        }
      }
    },
    cellStyle() {
      return 'white-space: pre-line'
    },
    // 处理分类的数据
    classifyDataHandle(classifyList) {
      let arr = []
      for (let item of classifyList) {
        // if (item.children && item.children.length > 0) {
        //   this.classifyDataHandle(item.children)
        // }
        if (!item.children) {
          item.children = []
          item.label = item.name
          item.value = item.id
          item.valueId = item.id
          item.icon && this.classifyIcons.push(item.icon)
        } else if (item.children && item.children.length > 0) {
          // item.label = item.children[0].name
          // item.value = item.children[0].id
          // item.valueId = item.children[0].id
          this.treeselectDeep(item, item.children)
        }
        arr.push([item])
      }
      return arr
    },
    treeselectDeep(parent, item) {
      if (Array.isArray(item)) {
        // 数组
        for (let i = 0; i < item.length; i++) {
          if (item[i].children) {
            this.treeselectDeep(item[i], item[i].children)
            parent.valueId = item[i].valueId
            parent.label = item[i].label
          } else {
            // item[i].valueId = item[i].children[0].id
            // item[i].label = item[i].children[0].name
            parent.valueId = item[i].id
            parent.label = item[i].name
            item[i].icon && this.classifyIcons.push(item[i].icon)
          }
        }
      } else if (item?.constructor === Object) {
        // 对象
        if (item.children && !Array.isArray(item)) {
          this.a(item, item.children)
        } else if (!item.children && !Array.isArray(item)) {
          parent.valueId = parent.children[0].id
          parent.label = parent.children[0].name
        }
      }
    },
    changeType(node, id) {
      // console.log(node)
      // console.log(id)
    },
    // 相关药品（属于该制剂的药品）调接口
    async fetchDrugData() {
      let relatedDrugRes = await getDrugDataApi(this.drugObj)
      if (relatedDrugRes.code === 200) {
        let drugTableData = relatedDrugRes.rows || []
        let ingredientArr = []
       /*  drugTableData[0].dkbDpSpecIngredientList = [
          {
            substanceId: 1,
            substanceName: 111
          },
          {
            substanceId: 2,
            substanceName: 222
          }
        ] */
        drugTableData.forEach(item => {
          let prodArr = []
          let manufacturerArr = []
          // 药品成分
          item.dkbDpSpecIngredientList.forEach(ingredient => {
            ingredientArr.push({
              substanceName: ingredient.substanceName,
              substanceId: ingredient.substanceId
            })
          })
          item.aliasName = item.dkbDpAliasList.find(alias => alias.isPrimary === '1').alias
          item.ingredientStr = ingredientArr.join('+')
          // 规格包装
          item.dkbPdProdList.forEach(prod => {
            let maxConcentration = ''
            if (item.dkbPpInfo && item.dkbPpInfo.showSpec) {
              maxConcentration = item.dkbPpInfo.showSpec + '*'
            }
            prodArr.push(maxConcentration + prod.packQty + prod.specUnitDesc + '/' + prod.packQtyUnitDesc)
          })
          item.prodStr = prodArr.join('\n')
          item.dkbDpManufacturerList.forEach(manufacture => {
            manufacturerArr.push(manufacture.manufacturerName)
          })
          item.manufacturerStr = manufacturerArr.join('\n')
        })
        this.drugTableData = drugTableData
        this.drugObj.total = relatedDrugRes.total
      }
    },
    // 相关药品（属于该制剂的药品）（生产厂商由一个变为多个）
    // handleFacturerList() {
    //   dkbDpManufacturerList
    // },
    /** 转换剂型树形数据结构 */
    normalizerTreeNode(node) {
      if (!(node.children && node.children.length > 0)) {
        delete node.children
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children
      }
    },
    // 同类药物制剂调接口
    async fetchSimilarData(scroll = false) {
      let similarRes = await getSimilarDataApi(this.similarObj)
      if (similarRes.code === 200) {
        if (!scroll) {
          this.similarTableData = similarRes.rows || []
        } else {
          this.similarTableData = this.similarTableData.concat(similarRes.rows)
        }
        this.similarObj.total = similarRes.total
      }
      /* $request.defaults.baseURL = 'http://localhost:8080'
      $request.post('/preparation/getSimilar', this.similarObj).then(similarRes => {
        console.log(similarRes)
        if (!scroll) {
          this.similarTableData = similarRes.rows || []
        } else {
          this.similarTableData = this.similarTableData.concat(similarRes.rows)
        }
        this.similarObj.total = similarRes.total
      }) */
    },
    // 跳转到对应的查看页面
    goToLink(row, type) {
      if (type === 'preparation') {
        // 制剂查看
        this.$router.push('/preparationSpecification/view/' + row.preparationId)
      } else if (type === 'substance') {
        // 物质查看
        this.$router.push('/substance/search/' + row.substanceId)
      } else if (type === 'drug') {
        // 药物查看
        this.$router.push('/drug/search/' + row.drugId)
      } else if (type === 'drugProd') {
        // 药品查看
        this.$router.push('/drugProd/view/' + row.id)
      } else if (type === 'drugGroup') {
        this.$router.push('/drugGroup/search/' + row.drugGroupId)
      }
    },
    refresh() {
      this.reload = false
      this.$nextTick(() => (this.reload = true))
    },
    // tab click
    jump(index, info) {
      let target = document.querySelector('.scroll-content')
      let scrollItems = document.querySelectorAll('.scroll-item')
      // 判断滚动条是否滚动到底部
      if (target.scrollHeight <= target.scrollTop + target.clientHeight) {
        this.tabIndex = index.index.toString()
      }
      let totalY = scrollItems[index.index].offsetTop - scrollItems[0].offsetTop // 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)
      let distance = document.querySelector('.scroll-content').scrollTop // 滚动条距离滚动区域顶部的距离
      // let distance = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset // 滚动条距离滚动区域顶部的距离(滚动区域为窗口)
      // 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次
      // 计算每一小段的距离
      let step = totalY / 50
      if (totalY > distance) {
        smoothDown(document.querySelector('.scroll-content'))
      } else {
        let newTotal = distance - totalY
        step = newTotal / 50
        smoothUp(document.querySelector('.scroll-content'))
      }

      // 参数element为滚动区域
      function smoothDown(element) {
        if (distance < totalY) {
          distance += step
          element.scrollTop = distance
          setTimeout(smoothDown.bind(this, element), 10)
        } else {
          element.scrollTop = totalY
        }
      }

      // 参数element为滚动区域
      function smoothUp(element) {
        if (distance > totalY) {
          distance -= step
          element.scrollTop = distance
          setTimeout(smoothUp.bind(this, element), 10)
        } else {
          element.scrollTop = totalY
        }
      }
    },
    // 滚动条滚动
    onScroll(e) {
      let scrollItems = document.querySelectorAll('.scroll-item')
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge = e.target.scrollTop >= scrollItems[i].offsetTop - scrollItems[0].offsetTop - 100
        if (judge) {
          this.tabIndex = i.toString()
          break
        }
      }
    },
    getHight() {
      this.contentStyleObj.height = (window.innerHeight - 190) + 'px'
    },
    //点击节点
    handleNodeClick(data) {
      this.node = data;
      // this.record.parentCode = data.id;
      this.$refs.treeForm.setCheckedKeys([data.id]);
    },
    //节点选中状态变化
    handleClick(data, checked, node){
      if(checked){
        this.node = data;
        // this.record.parentCode = data.id;
        this.$refs.treeForm.setCheckedKeys([data.id]);
      } else {
        this.node = {};
        // this.record.parentCode = '';
        this.$refs.treeForm.setCheckedKeys([]);
      }
    },
    //点击复选框
    handleNodeCheck(data, checked) {
      if (checked.checkedKeys.length > 0) {
        this.node = data;
        // this.record.parentCode = data.id;
        this.$refs.treeForm.setCheckedKeys([data.id]);
      } else {
        this.node = {};
        // this.record.parentCode = '';
        this.$refs.treeForm.setCheckedKeys([]);
      }
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.getHight)
  },
}
</script>
<style lang="scss" scoped>
.preparation-wrap {
  background: #EBEFF7;
  .el-form-item{
    line-height: 20px!important;
    font-size: 14px;
    font-family: 'pingfang-regular';
    font-weight: 400;
    color: #333333;
    margin-bottom: 10px !important;
  }

  .el-form-item__label{
    line-height: 30px!important;
  }
  .el-form-item__content{
    line-height: 30px!important;
  }
  .el-tabs__header{
    margin-bottom: 0px!important;
  }
}
</style>
<style lang="scss" scoped>
  
  .el-tree-node__content:hover, .el-tree-node__content:focus {
    background-color: transparent !important;
  }
  .el-tree-node__content {
    background-color: transparent !important;
  }
  .el-tree-node .is-current {
    background-color: transparent !important;
  }
  .content {
    margin-left: 10px !important;
    padding-left: 20px !important;
  }
  .content.bacg {
    margin-top: 10px;
  }
  .el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .classify-li {
    margin: 10px !important;
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
  .classify-tree {
    border: 1px solid #638BF7;
    border-radius: 15px;
    padding: 0 5px;
    min-height: 52px;
  }
.link {
  font-size: 14px;
  font-family: 'pingfang-regular';
  font-weight: 400;
  text-decoration: underline;
  color: #2B60F8;
  /* color: red; */
  cursor: pointer;
}
.unit-link {
  color: #333;
  cursor: pointer;
  text-decoration: underline;
}
.substanceName{
  height: 40px;
  line-height: 40px;
  background: #FFFFFF;
  box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
  border-radius: 4px;
  span{
    font-size: 16px;
    color: #333333;
    /* font-family: Microsoft JhengHei; */
    font-weight: 600;
  }
  //.el-icon-info{
  //  color: #E99B00;
  //  font-size: 16px;
  //  margin:0px 5px 0px 20px;
  //}
}
.el-icon-info{
  color: #E99B00;
  font-size: 16px;
  margin-right:5px;
}
.substanceStructure{
  height: 40px;
  height: auto!important;
  min-height: 40px;
  background: #FFFFFF;
  box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
  border-radius: 4px;
  margin: 10px 0px;
  padding-bottom: 20px;
}
.substanceStructure.bacg-el-row {
  padding-bottom: 10px;
}
.substanceTitle{
  font-size: 16px;
  /* font-family: Microsoft JhengHei; */
  font-weight: 600;
  padding:16px 0px 5px 19px;
  color: #333333;
}
ul,li{ padding:0;margin:0;list-style:none}
li.background-li{
  float: left;
  margin-right: 5px;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
  background: #E6ECFE;
  border-radius: 3px;
  padding:0px 10px;
}
li.no-data-li {
  color: #999999;
  font-size: 14px;
}
.substanceTable{
  padding:10px 20px 0px;
}
</style>

